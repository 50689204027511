export const snapshotEnums = {
    data() {
        return {
            snapshotTypes: {
                QUICK_SNAPSHOT: 20,
                AUTO_SNAPSHOT: 30,
                DETAILED_SNAPSHOT: 10,
                ARCHIVED_SNAPSHOT: 40,
                RESTED_SNAPSHOT: 50
            },
            fileStatusTypes: {
                SAVED_AND_UNCHANGED: 'SAVED_AND_UNCHANGED',
                SAVED_AND_CHANGED: 'SAVED_AND_CHANGED',
                UNSAVED: 'UNSAVED',
                STATUS_UNAVAILABLE: 'STATUS_UNAVAILABLE'
            },
            snapshotBackupStatusOptions: {
                CREATING_SNAPSHOT: 'creating'
            }
        }
    },
    methods: {
        getSnapshotStatusIcon: function (snapshotData) {
            if (!snapshotData.backup_location) {
                return 'mdi-lock-off'
            } else if (snapshotData.backup_location === this.snapshotBackupStatusOptions.CREATING_SNAPSHOT) {
                return 'mdi-lock-clock'
            } else {
                return 'mdi-lock-check'
            }
        },
        getSnapshotStatusDescription: function (snapshotData) {
            if (!snapshotData.filesystem_prefix && !snapshotData.backup_location) {
                return 'The data for this snapshot can not be located. Please contact support.'
            } else if (snapshotData.filesystem_prefix && !snapshotData.backup_location) {
                return 'This snapshot has no backup.'
            } else if (snapshotData.filesystem_prefix && snapshotData.backup_location === this.snapshotBackupStatusOptions.CREATING_SNAPSHOT) {
                return 'A backup is being created for this snapshot.'
            } else if (snapshotData.filesystem_prefix && snapshotData.backup_location) {
                return 'A backup exists for this snapshot'
            } else if (!snapshotData.filesystem_prefix && snapshotData.backup_location === this.snapshotBackupStatusOptions.CREATING_SNAPSHOT) {
                return 'The data for this snapshot can not be located. Please contact support.'
            } else if (!snapshotData.filesystem_prefix && snapshotData.backup_location) {
                return 'Files in this snapshot are in cold storage. It can take slightly longer to view or download them.'
            }
        },
        currentFileStatus(file) {
            if (file.status) {
                if (file.status.success && file.status.in_last_snapshot && !file.status.changed) {
                    return this.fileStatusTypes.SAVED_AND_UNCHANGED
                } else if (file.status.success && file.status.in_last_snapshot && file.status.changed) {
                    return this.fileStatusTypes.SAVED_AND_CHANGED
                } else if (file.status.success && !file.status.in_last_snapshot) {
                    return this.fileStatusTypes.UNSAVED
                }
            }
            return this.fileStatusTypes.STATUS_UNAVAILABLE
        }
    }
}

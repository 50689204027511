export const createQuickAndDetailedSnapshots = {
    data() {
        return {
            snapshotCreationLoading: false,
            snapshotCreationErrorContent: 'An error has occurred while creating your snapshot, please try again later.',
            snapshotCreationError: false
        }
    },
    methods: {
        addSnapshot: function (newSnapshotType, longId = null, shortId = null, description = null) {
            this.snapshotCreationError = false
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Saving snapshot, please wait..',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            let postBody
            if (newSnapshotType === this.snapshotTypes.QUICK_SNAPSHOT) {
                const today = new Date()
                const currentDate = today.toJSON().slice(0, 10)
                const currentTime = ('0' + today.getHours()).slice(-2) + ':' + ('0' + today.getMinutes()).slice(-2) + ':' + ('0' + today.getSeconds()).slice(-2)
                const datetimeLongId = currentDate + ' / ' + currentTime
                const datetimeShortId = datetimeLongId.replace(/\/+/g, '_').replace(/\s+/g, '').replace('-', '_').replace(/:/g, '_')
                postBody = {
                    description: 'Quick snapshot - no description available',
                    long_id: 'Quick snapshot ' + datetimeLongId,
                    short_id: datetimeShortId,
                    snapshot_type: this.snapshotTypes.QUICK_SNAPSHOT
                }
            } else if (newSnapshotType === this.snapshotTypes.DETAILED_SNAPSHOT) {
                this.snapshotCreationLoading = true
                postBody = {
                    description,
                    long_id: longId,
                    short_id: shortId,
                    snapshot_type: this.snapshotTypes.DETAILED_SNAPSHOT
                }
            }
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            const apiURL = `/instances/${this.$route.params.iid}/snapshots_async`
            this.$axios
                .post(apiURL, postBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Snapshot creation has started, you can track its progress <a href="${userTasksRoute}">here</a>`,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'info'
                    })
                    if (newSnapshotType === this.snapshotTypes.DETAILED_SNAPSHOT) {
                        this.$router.push({
                            name: 'snapshot-overview',
                            params: {
                                oid: this.$route.params.oid,
                                sid: this.$route.params.sid,
                                iid: this.$route.params.iid,
                                snid: this.$route.params.snid
                            }
                        })
                    }
                })
                .catch(error => {
                    this.$store.dispatch('hideSnackBar')
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.reason &&
                        error.response.data.reason[0].includes('duplicate key value violates unique constraint "uc_iid_long"')
                    ) {
                        this.snapshotCreationError = true
                        this.snapshotCreationErrorContent = 'Another snapshot uses the same name you chose for this snapshot, please choose another name'
                    } else {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to create a snapshot, please try again later.',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    }
                })
                .finally(() => {
                    this.snapshotCreationLoading = false
                })
        }
    }
}

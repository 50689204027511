import { sortArray, isEditorRole, isViewerRole, isDistributedInstance, isCurrentState } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import { sortBy } from 'lodash'
export const breadcrumbs = {
    data: function () {
        return {
            space: 'select space',
            instance: 'select instance',
            snapshot: 'select snapshot',
            organizationSearch: '',
            spaceSearch: '',
            instanceSearch: '',
            snapshotSearch: '',
            organizationMenu: false,
            spaceMenu: false,
            instanceMenu: false,
            snapshotMenu: false
        }
    },
    methods: {
        sortArray: function () {
            return sortArray
        },
        fetchOrgSpaces: function (orgName) {
            this.organizationMenu = false
            this.space = 'select space'
            const org = this.userOrgs.filter(org => org.long_id === orgName)[0]
            this.$router.push({ name: 'home-dashboard', params: { oid: org.oid } })
        },
        openSpace(spaceName, sid, iid, snid) {
            this.spaceMenu = false
            this.space = spaceName
            this.$router.push({ name: 'snapshot-overview', params: { oid: this.$route.params.oid, sid, iid, snid } })
        },
        openSnapshot(snid) {
            this.snapshotMenu = false
            this.$router.push({
                name: 'snapshot-overview',
                params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: this.$route.params.iid, snid }
            })
        },
        goToSnapshotTimelineView: function () {
            this.snapshotMenu = false
            this.$router.push({
                name: 'instance-snapshots',
                params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: this.$route.params.iid, snid: this.$route.params.snid }
            })
        },
        spaceRole: function (role) {
            if (role) {
                if (role.toUpperCase().includes('ADMIN')) {
                    return 'SPACE ADMIN'
                } else {
                    return 'MEMBER'
                }
            } else {
                return 'UNAVAILABLE'
            }
        },
        showInstanceOpenSnackBar(instanceName) {
            this.$store.dispatch('showSnackBar', {
                snackBarText: `Moved to instance: ${instanceName}`,
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })
        },
        fetchDistributedInstanceSnapshots(iid, routeName = 'snapshot-overview', extraParams = {}) {
            this.$axios
                .get(`instances/${iid}/snapshots`)
                .then(response => {
                    if (response.data && response.data.length < 2) {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Distributed has no snapshots',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        })
                    } else if (response.data && response.data.length > 1) {
                        const sortedSnapshots = sortArray(response.data, 'snid', 'descending', false)
                        const nonDevelopmentSnapshots = sortedSnapshots.filter(snapshot => snapshot.long_id !== 'Current State')
                        const mostRecentSnapshot = nonDevelopmentSnapshots[0]
                        this.$router.push({
                            name: routeName,
                            params: {
                                oid: this.$route.params.oid,
                                sid: this.$route.params.sid,
                                iid: mostRecentSnapshot.iid,
                                snid: mostRecentSnapshot.snid,
                                ...extraParams
                            }
                        })
                        this.showInstanceOpenSnackBar(mostRecentSnapshot.long_id)
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch snapshots of distributed',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
        },
        async openInstance(instanceName, iid, role) {
            // instanceName => NO NEED, less input the better!
            await this.$store.dispatch('instanceStore/fetchSnapshots', iid)
            if (isEditorRole(role) && !isDistributedInstance(instanceName)) {
                const sortedInstanceSnapshots = sortArray(this.instanceSnapshots, 'snid', 'ascending', false)
                if (sortedInstanceSnapshots.length) {
                    this.$router.push({
                        name: 'snapshot-overview',
                        params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid, snid: sortedInstanceSnapshots[0].snid }
                    })
                    this.showInstanceOpenSnackBar(instanceName)
                }
            } else if (isDistributedInstance(instanceName)) {
                this.fetchDistributedInstanceSnapshots(iid)
            } else if (isViewerRole(role)) {
                const sortedInstanceSnapshots = sortArray(this.instanceSnapshots, 'snid', 'descending', false)
                this.$router.push({
                    name: 'snapshot-overview',
                    params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid, snid: sortedInstanceSnapshots[0].snid }
                })
                this.showInstanceOpenSnackBar(instanceName)
            }
        },
        disabledInstance(snapshots) {
            if (!snapshots.length) {
                return true
            } else if (snapshots.length >= 1) {
                return false
            }
        }
    },
    computed: {
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isDistributedInstance']),
        ...mapState('instanceStore', ['instanceSnapshots']),
        ...mapState(['userOrgs']),
        ...mapState('orgStore', ['educationSpaces', 'researchSpaces', 'datasetSpaces', 'spacesWithPrimarySnapshots', 'fetchingSpaces']),
        ...mapState('spaceStore', ['spaceInstances', 'fetchingInstances']),
        ...mapState('instanceStore', ['fetchingSnapshots']),
        ...mapGetters('snapshotStore', ['isDevelopment', 'nonDevelopmentSnapshots']),
        ...mapGetters('orgStore', ['orgLongName']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        organizationName() {
            if (this.$route.params.oid !== undefined) {
                return this.orgLongName
            } else {
                return this.orgNames[0]
            }
        },
        instanceName() {
            if (this.$route.params.iid !== undefined && this.spaceInstances && this.spaceInstances.length) {
                return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
            } else {
                return 'select instance'
            }
        },
        spaceName() {
            if (this.$route.params.sid !== undefined && this.spacesWithPrimarySnapshots && this.spacesWithPrimarySnapshots.length) {
                return this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
            } else {
                return 'select space'
            }
        },
        snapshotName() {
            if (this.$route.params.snid !== undefined && this.instanceSnapshots && this.instanceSnapshots.length) {
                return this.$store.getters['snapshotStore/snapshotLongNameById'](this.$route.params.snid)
            } else {
                return 'select snapshot'
            }
        },
        orgNames() {
            return this.userOrgs
                .map(org => org.long_id)
                .sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase())
                })
        },
        sortedOrgNames() {
            if (this.organizationSearch.length) {
                return sortArray(this.userOrgs, 'long_id', 'ascending', true).filter(
                    org => org.long_id.toLowerCase().indexOf(this.organizationSearch.toLowerCase()) !== -1
                )
            } else {
                return sortArray(this.userOrgs, 'long_id', 'ascending', true)
            }
        },
        sortedEducationSpaces() {
            if (this.spaceSearch.length) {
                return this.educationSpaces.filter(space => space.space_long_id.toLowerCase().indexOf(this.spaceSearch.toLowerCase()) !== -1)
            } else {
                return this.educationSpaces
            }
        },
        sortedResearchSpaces() {
            if (this.spaceSearch.length) {
                return this.researchSpaces.filter(space => space.space_long_id.toLowerCase().indexOf(this.spaceSearch.toLowerCase()) !== -1)
            } else {
                return this.researchSpaces
            }
        },
        sortedDatasetSpaces() {
            if (this.spaceSearch.length) {
                return this.datasetSpaces.filter(space => space.space_long_id.toLowerCase().indexOf(this.spaceSearch.toLowerCase()) !== -1)
            } else {
                return this.datasetSpaces
            }
        },
        sortedInstances() {
            if (this.instanceSearch.length) {
                return sortArray(this.spaceInstances, 'iid', 'ascending', false).filter(
                    instance => instance.long_id.toLowerCase().indexOf(this.instanceSearch.toLowerCase()) !== -1
                )
            } else {
                return this.spaceInstances
            }
        },
        sortedSnapshots() {
            if (this.snapshotSearch.length) {
                return sortBy(this.instanceSnapshots, 'snapshot_timestamp')
                    .reverse()
                    .filter(snapshot => !isCurrentState(snapshot.short_id) && snapshot.long_id.toLowerCase().indexOf(this.snapshotSearch.toLowerCase()) !== -1)
            } else {
                return sortBy(this.instanceSnapshots, 'snapshot_timestamp')
                    .reverse()
                    .filter(snapshot => !isCurrentState(snapshot.short_id))
            }
        },
        currentState() {
            if (this.snapshotSearch.length) {
                return this.instanceSnapshots.filter(
                    snapshot => snapshot.long_id === 'Current State' && snapshot.long_id.toLowerCase().indexOf(this.snapshotSearch.toLowerCase()) !== -1
                )
            } else {
                return this.instanceSnapshots.filter(snapshot => snapshot.long_id === 'Current State')
            }
        },
        showOrgMenu() {
            if (this.orgNames.length) {
                return true
            } else {
                return false
            }
        },
        showSpaceMenu() {
            if (this.spacesWithPrimarySnapshots.length && !this.fetchingSpaces) {
                return true
            } else {
                return false
            }
        },
        showInstanceMenu() {
            if (!this.fetchingInstances && !this.fetchingSpaces && this.$route.params.snid !== undefined) {
                return true
            } else {
                return false
            }
        },
        showSnapshotMenu() {
            if (this.instanceSnapshots.length && !this.fetchingSnapshots && !this.fetchingInstances && !this.fetchingSpaces) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        organizationMenu: function (val) {
            if (val) {
                this.organizationSearch = ''
            }
        },
        spaceMenu: function (val) {
            if (val) {
                this.spaceSearch = ''
            }
        },
        instanceMenu: function (val) {
            if (val) {
                this.instanceSearch = ''
            }
        },
        snapshotMenu: function (val) {
            if (val) {
                this.snapshotSearch = ''
            }
        }
    }
}

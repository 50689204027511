<template>
    <div class="flex-grow-1 flex-shrink-1" style="min-width: 0">
        <div class="d-flex justify-start align-center">
            <v-menu v-model="organizationMenu" offset-y v-if="showOrgMenu" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="fetchingSpaces" v-on="on" text color="secondary" class="flex-shrink-1 full-width-button ml-3" e2e-select-org>
                        <div class="d-flex align-center">
                            <div class="secondary--text font-weight-bold caption text-truncate flex-shrink-1">{{ organizationName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height: 500px" class="overflow-y-auto" nav dense>
                    <v-list-item>
                        <v-text-field
                            autofocus
                            v-model="organizationSearch"
                            class="ma-0 pa-0"
                            append-icon="search"
                            label="Filter"
                            dense
                            single-line
                            outlined></v-text-field>
                    </v-list-item>
                    <v-list-item disabled>
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text caption text-uppercase d-flex align-center">
                                <div class="d-flex flex-column w-100">
                                    <div class="d-flex align-center">
                                        <v-icon small class="mr-1">apartment</v-icon>
                                        organizations
                                    </div>
                                    <v-divider class="mt-1" color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="item in sortedOrgNames" :key="item.long_id" @click="fetchOrgSpaces(item.long_id)">
                        <v-list-item-title>
                            <div class="d-flex justify-space-between align-center">
                                {{ item.long_id }}
                                <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ item.role }}</v-chip>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-icon>keyboard_arrow_right</v-icon>
            <v-menu v-model="spaceMenu" offset-y v-if="showSpaceMenu" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text color="secondary" class="flex-shrink-1 full-width-button" e2e-select-space>
                        <div class="d-flex align-center">
                            <div class="secondary--text font-weight-bold caption text-truncate flex-shrink-1">{{ spaceName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height: 500px" class="overflow-y-auto" nav dense>
                    <v-list-item>
                        <v-text-field
                            v-model="spaceSearch"
                            autofocus
                            class="ma-0 pa-0"
                            append-icon="search"
                            label="Filter"
                            dense
                            single-line
                            outlined></v-text-field>
                    </v-list-item>
                    <v-list-item>
                        <v-alert class="my-0" dense text color="info">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a
                                        v-on="on"
                                        style="text-decoration: none"
                                        href="https://docs.nuvolos.cloud/getting-started/nuvolos-basic-concepts/distribution"
                                        target="_blank"
                                        class="caption">
                                        Want to move objects across spaces?
                                    </a>
                                </template>
                                <span>Click to learn more.</span>
                            </v-tooltip>
                        </v-alert>
                    </v-list-item>
                    <div v-if="sortedEducationSpaces.length">
                        <v-list-item disabled>
                            <v-list-item-content>
                                <v-list-item-title class="secondary--text caption text-uppercase d-flex align-center">
                                    <div class="d-flex flex-column w-100">
                                        <div class="d-flex align-center">
                                            <v-icon small class="mr-1">school</v-icon>
                                            Courses
                                        </div>
                                        <v-divider class="mt-1" color="grey"></v-divider>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-for="item in sortedEducationSpaces"
                            :key="item.sid"
                            @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)">
                            <v-list-item-title>
                                <div class="d-flex justify-space-between align-center">
                                    {{ item.space_long_id }}
                                    <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <div v-if="sortedResearchSpaces.length">
                        <v-list-item disabled :class="sortedEducationSpaces.length ? 'mt-5' : ''">
                            <v-list-item-content>
                                <v-list-item-title class="secondary--text caption text-uppercase d-flex align-center">
                                    <div class="d-flex flex-column w-100">
                                        <div class="d-flex align-center">
                                            <v-icon small class="mr-1">mdi-beaker</v-icon>
                                            research projects
                                        </div>
                                        <v-divider class="mt-1" color="grey"></v-divider>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="item in sortedResearchSpaces" :key="item.sid" @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)">
                            <v-list-item-title>
                                <div class="d-flex justify-space-between align-center">
                                    {{ item.space_long_id }}
                                    <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <div v-if="sortedDatasetSpaces.length">
                        <v-list-item disabled :class="sortedResearchSpaces.length || sortedEducationSpaces.length ? 'mt-5' : ''">
                            <v-list-item-content>
                                <v-list-item-title class="secondary--text caption text-uppercase d-flex align-center">
                                    <div class="d-flex flex-column w-100">
                                        <div class="d-flex align-center">
                                            <v-icon small class="mr-1">mdi-database</v-icon>
                                            datasets
                                        </div>
                                        <v-divider class="mt-1" color="grey"></v-divider>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="item in sortedDatasetSpaces" :key="item.sid" @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)">
                            <v-list-item-title>
                                <div class="d-flex justify-space-between align-center">
                                    {{ item.space_long_id }}
                                    <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-menu>
            <v-progress-circular v-else-if="fetchingSpaces" indeterminate></v-progress-circular>
            <v-icon v-if="fetchingInstances || showInstanceMenu">keyboard_arrow_right</v-icon>
            <v-menu v-model="instanceMenu" offset-y v-if="showInstanceMenu">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text color="secondary" class="flex-shrink-1 full-width-button shepherd-toolbar-instance" e2e-select-instance>
                        <div class="d-flex align-center">
                            <div class="secondary--text font-weight-bold caption text-truncate flex-shrink-1">{{ instanceName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height: 500px" class="overflow-y-auto" nav dense>
                    <v-list-item>
                        <v-text-field
                            v-model="instanceSearch"
                            autofocus
                            class="ma-0 pa-0"
                            append-icon="search"
                            label="Filter"
                            dense
                            single-line
                            outlined></v-text-field>
                    </v-list-item>
                    <v-list-item disabled>
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text caption text-uppercase d-flex align-center">
                                <div class="d-flex flex-column w-100">
                                    <div class="d-flex align-center">
                                        <v-icon small class="mr-1">group</v-icon>
                                        instances
                                    </div>
                                    <v-divider class="mt-1" color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-for="item in sortedInstances"
                        :key="item.iid"
                        @click="openInstance(item.long_id, item.iid, item.role)"
                        :disabled="disabledInstance(item.long_id, item.data)">
                        <v-list-item-title>
                            <div class="d-flex justify-space-between align-center">
                                {{ item.long_id }}
                                <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ item.role }}</v-chip>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-progress-circular :size="30" color="secondary" indeterminate v-else-if="fetchingInstances"></v-progress-circular>
            <v-icon v-if="fetchingSnapshots || showSnapshotMenu">keyboard_arrow_right</v-icon>
            <v-menu v-model="snapshotMenu" offset-y v-if="showSnapshotMenu" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text color="secondary" class="flex-shrink-1 full-width-button shepherd-toolbar-snapshot" e2e-select-snapshot>
                        <div class="d-flex align-center">
                            <div class="secondary--text font-weight-bold caption text-truncate flex-shrink-1">{{ snapshotName }}</div>
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list style="max-height: 500px" class="overflow-y-auto" nav dense v-model="snapshot">
                    <v-list-item>
                        <div class="d-flex justify-space-between w-100">
                            <v-text-field
                                v-model="snapshotSearch"
                                autofocus
                                class="ma-0 pa-0"
                                append-icon="search"
                                label="Filter"
                                dense
                                single-line
                                outlined></v-text-field>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        icon
                                        :loading="fetchingSnapshots"
                                        v-on="on"
                                        @click="$store.dispatch('instanceStore/fetchInstanceSnapshots', $route.params.iid)">
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh snapshots</span>
                            </v-tooltip>
                        </div>
                    </v-list-item>
                    <v-list-item disabled v-if="currentState.length && isInstanceEditor && !isDistributedInstance">
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text caption text-uppercase d-flex align-center flex-wrap">
                                <div class="d-flex flex-column w-100">
                                    <div class="d-flex align-center">
                                        <v-icon small class="mr-1">build</v-icon>
                                        mutable state
                                    </div>
                                    <v-divider class="mt-1" color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="currentState.length && isInstanceEditor && !isDistributedInstance" @click="openSnapshot(currentState[0].snid)">
                        <v-list-item-title>{{ currentState[0].long_id }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text caption text-uppercase d-flex align-center flex-wrap">
                                <div class="d-flex flex-column w-100">
                                    <div class="d-flex justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <v-icon small class="mr-1">camera_alt</v-icon>
                                            immutable states
                                        </div>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" small color="info" icon @click="goToSnapshotTimelineView()">
                                                    <v-icon>timeline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Timeline view of snapshots</span>
                                        </v-tooltip>
                                    </div>
                                    <v-divider color="grey"></v-divider>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <div v-if="sortedSnapshots.length">
                        <v-list-item v-for="item in sortedSnapshots" :key="item.snid" @click="openSnapshot(item.snid)">
                            <v-list-item-title>{{ item.long_id }}</v-list-item-title>
                        </v-list-item>
                    </div>
                    <template v-else-if="currentSpaceType !== spaceTypes.VENDOR_SPACE && isDevelopment && isInstanceEditor">
                        <v-list-item v-if="!isTrialSpace" @click="addSnapshot(snapshotTypes.QUICK_SNAPSHOT)" :disabled="isSpaceArchived">
                            <v-list-item-title class="subtitle-2 d-flex align-center">
                                <v-icon small class="mr-1">mdi-camera-plus-outline</v-icon>
                                Quick snapshot
                            </v-list-item-title>
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                    </template>
                                    <span>This will create and save a snapshot of your current state, including all your files, tables, and applications.</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-tooltip v-else right>
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-list-item disabled>
                                        <v-list-item-title class="subtitle-2 d-flex align-center">
                                            <v-icon small class="mr-1">mdi-camera-plus-outline</v-icon>
                                            Quick snapshot
                                        </v-list-item-title>
                                        <v-list-item-action>
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on1 }">
                                                    <v-icon v-on="on1" small color="grey lighten-1">mdi-information</v-icon>
                                                </template>
                                            </v-tooltip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </div>
                            </template>
                            <span>
                                You discovered a premium feature!
                                <br />
                                Subscribing to Nuvolos will unlock it.
                            </span>
                        </v-tooltip>
                    </template>
                </v-list>
            </v-menu>
            <v-progress-circular v-else-if="fetchingSnapshots" indeterminate></v-progress-circular>
        </div>
    </div>
</template>
<script>
import { breadcrumbs } from '@/mixins/breadcrumbs'
import { enumsData } from '@/mixins/enums'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
import { mapGetters } from 'vuex'
import { snapshotEnums } from '@/mixins/snapshot'

export default {
    name: 'BreadcrumbsLargeScreen',
    mixins: [breadcrumbs, enumsData, createQuickAndDetailedSnapshots, snapshotEnums],
    computed: {
        ...mapGetters('spaceStore', ['currentSpaceType', 'isTrialSpace', 'isSpaceArchived']),
        ...mapGetters('instanceStore', ['isInstanceEditor'])
    }
}
</script>
